import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroImage from "../components/HeroImage"
import Intro from "../components/Intro"
import AllBlogPosts from "../components/AllBlogPosts"

const blog = props => {
  const location = props.location.pathname
  const { acf } = props.data.page

  const metaTitle = acf._swb_theme_meta_title
  const metaDescription = acf._swb_theme_description
  const metaImage = acf._swb_theme_image.localFile.publicURL

  const heroImgFluid = acf._ikag_hero_image.localFile.childImageSharp.fluid

  const introTitle = acf._ikag_intro_title
  const introItalics = acf._ikag_intro_title_italic
  const introContent = acf._ikag_intro_content

  const {
    posts: { edges: posts },
    morePosts: { edges: morePosts },
  } = props.data

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage fluid={heroImgFluid} />
      <Intro title={introTitle} italic={introItalics} content={introContent} />
      <AllBlogPosts posts={posts} morePosts={morePosts} />
    </Layout>
  )
}

export const homeQuery = graphql`
  query blogPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
        _ikag_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_intro_title
        _ikag_intro_title_italic
        _ikag_intro_content
      }
    }

    posts: allWordpressPost(sort: { fields: [date], order: [DESC] }) {
      edges {
        node {
          title
          id
          slug
          excerpt
          date(formatString: "MMMM D, Y")
          categories {
            name
          }
          acf {
            _ikag_post_hero_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }

            _ikag_post_excerpt
          }
        }
      }
    }
    morePosts: allWordpressPost(
      sort: { fields: [date], order: [DESC] }
      skip: 12
    ) {
      edges {
        node {
          title
          id
          slug
          excerpt
          date(formatString: "MMMM D, Y")
          categories {
            name
          }
          acf {
            _ikag_post_hero_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            _ikag_post_excerpt
          }
        }
      }
    }
  }
`

export default blog
