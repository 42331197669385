import React from "react"
import { Link } from "gatsby"
import BgImg from "gatsby-background-image"
import styled, { keyframes, css } from "styled-components"

import { buttonOne, bodyCopy, headlineThree, colors } from "../Utilities"

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
`

const complexMixin = css`
  animation-name: ${fadeIn};
  animation-duration: 1.5s;
  animation-delay: ${props => props.delay};
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
`

const PostCardStyled = styled.article`
  ${complexMixin};
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  padding-bottom: 4rem;
  ${props => (props.removeAnimation === false ? complexMixin : false)}

  @media (min-width: 768px) {
    width: calc(50% - 4rem);
    padding-bottom: 2.5rem;
    margin: 2rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.3333% - 4rem);
    margin: 2rem;
    padding-bottom: 5rem;
  }

  .post-bg-img {
    width: 100%;
    min-height: 30rem;
  }

  .post-title {
    margin-top: 2rem;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    h2 {
      ${headlineThree};
      margin: 0;
      color: ${colors.colorSecondary};

      a {
        color: ${colors.colorSecondary};
        font-size: 1em;
      }
    }
  }

  .post-date {
    margin-bottom: 1.5rem;
    p {
      ${bodyCopy};
      margin: 0;
      color: ${colors.grey};
      text-transform: uppercase;
    }
  }

  .post-excerpt {
    width: 100%;
    max-width: 100%;
    p {
      ${bodyCopy};
      color: ${colors.grey};
    }
  }

  .post-read-more {
    text-align: left;

    a {
      ${buttonOne};
      min-width: auto;
      padding-left: 0;
    }
  }

  &.big-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      padding-bottom: 0;
    }

    @media (min-width: 1025px) {
      margin-bottom: 4rem;
      padding-bottom: 0;
    }

    .post-bg-img {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50% - 4rem);
        margin-right: 4rem;
      }
    }

    .post-content {
      width: 100%;

      @media (min-width: 768px) {
        position: relative;
        width: calc(50% - 4rem);
        margin-left: 4rem;
        padding-bottom: 5rem;
      }
    }

    .post-read-more {
      bottom: 1rem;
    }
  }
`

const BlogPostCard = ({ post, position }) => {
  let cardClass = ""
  if (position < 3) cardClass = "big-card"
  const imageFluid =
    post.acf &&
    post.acf._ikag_post_hero_image &&
    post.acf._ikag_post_hero_image.localFile &&
    post.acf._ikag_post_hero_image.localFile.childImageSharp &&
    post.acf._ikag_post_hero_image.localFile.childImageSharp.fluid

  return (
    <PostCardStyled className={`${cardClass}`}>
      {imageFluid && (
        <BgImg
          className="post-bg-img"
          Tag="div"
          fluid={post.acf._ikag_post_hero_image.localFile.childImageSharp.fluid}
        />
      )}
      <div className="post-content">
        <div className="post-title">
          <h2>
            <Link to={`/blog/${post.slug}`}>{post.title}</Link>
          </h2>
        </div>
        <div className="post-date">
          <p>{post.date}</p>
        </div>
        <div
          className="post-excerpt"
          dangerouslySetInnerHTML={{ __html: post.acf._ikag_post_excerpt }}
        />
        <div className="post-read-more">
          <Link to={`/blog/${post.slug}`}>Read More</Link>
        </div>
      </div>
    </PostCardStyled>
  )
}

export default BlogPostCard
