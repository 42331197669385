import React, { Component } from "react"
import styled from "styled-components"

import { medWrapper, buttonOne } from "../Utilities"
import BlogPostCard from "./BlogPostCard"

const AllBlogPostsStyled = styled.div`
  .wrapper {
    ${medWrapper};
    justify-content: flex-start;
    overflow: hidden;
  }

  .load-more-posts {
    width: 100%;
    margin: 5rem auto;
    text-align: center;

    button {
      ${buttonOne};
    }
  }
`

class AllBlogPosts extends Component {
  constructor(props) {
    super(props)

    this.loadMorePosts = this.loadMorePosts.bind(this)
    this.checkForMore = this.checkForMore.bind(this)

    this.state = {
      allPosts: [],
      currentShow: [],
      noMorePosts: false,
    }
  }

  componentDidMount() {
    this.setState(prevState => {
      return {
        ...prevState,
        allPosts: this.props.posts,
        currentShow: this.props.posts.slice(0, 6),
      }
    }, this.checkForMore)
  }

  checkForMore() {
    if (this.state.allPosts.length <= this.state.currentShow.length) {
      this.setState(prevState => {
        return {
          ...prevState,
          noMorePosts: true,
        }
      })
    }
  }

  loadMorePosts() {
    this.setState(prevState => {
      return {
        ...prevState,
        currentShow: this.state.allPosts.slice(
          0,
          prevState.currentShow.length + 3
        ),
      }
    }, this.checkForMore)
  }

  render() {
    return (
      <AllBlogPostsStyled>
        <div className="wrapper">
          {this.state.currentShow.map((post, index) => {
            return (
              <BlogPostCard
                key={post.node.id}
                position={index}
                post={post.node}
              />
            )
          })}
          <div className="load-more-posts">
            <button
              disabled={this.state.noMorePosts}
              onClick={this.loadMorePosts}
            >
              {this.state.noMorePosts ? "no more posts" : "Load More"}
            </button>
          </div>
        </div>
      </AllBlogPostsStyled>
    )
  }
}

export default AllBlogPosts
